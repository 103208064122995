import * as React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import styled from "styled-components"

const ContactStyles = styled.div`
  display: flex;
  padding: 50px;
  grid-gap: 50px;
  gap: 50px;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  width: 85%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    grid-gap: 0px;
    gap: 0px;
    width: 100%;
    padding: 20px;
  }
`
const MessageStyles = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;

  p,
  h2 {
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const FormStyles = styled.form`
  background: #1f1f1f;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 #000;
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .details {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;

    input {
      width: 100%;
      resize: none;
      padding: 10px 20px;
      outline: none;
      border: 1px solid #839ef6;
      background: transparent;
      color: #f1f7ee;
    }
  }

  .message {
    margin: 15px 0;

    p {
      margin-bottom: 10px;
    }
    textarea {
      width: 100%;
      resize: none;
      padding: 10px 20px;
      outline: none;
      border: 1px solid #839ef6;
      background: transparent;
      color: #f1f7ee;
    }
  }

  button {
    padding: 5px 15px;
    font-size: 1rem;
    background: #222;
    border: 3px solid #839ef6;
    color: #f1f7ee;
    box-shadow: 2px 2px 0 0 #839ef6;
    cursor: pointer;
    transition: all 0.22s ease-in-out;
  }
`

const contact = () => {
  return (
    <>
      <Seo title="Contact" />
      <Layout>
        <SimpleBanner title="Get in touch">
          <StaticImage
            className="banner__image"
            src="https://imgur.com/NSlWP1f.jpg"
            alt="Selfie green"
          />
        </SimpleBanner>
        <ContactStyles>
          <MessageStyles>
            <h2>Let's talk?</h2>
            <p className="contact-body">
              Please feel free to message me via contact form or email.
            </p>
          </MessageStyles>
          <FormStyles name="contact form" method="POST" data-netlify="true">
            <div class="details">
              <input type="hidden" name="form-name" value="contact-form" />
              <input type="text" name="name" placeholder="Full name" />
              <input type="text" name="email" placeholder="Email" />
              <input type="text" name="number" placeholder="Phone number" />
            </div>
            <div className="message">
              <p>Message</p>
              <textarea rows="4" name="message"></textarea>
            </div>
            <button type="submit">Submit</button>
          </FormStyles>
        </ContactStyles>
      </Layout>
    </>
  )
}

export default contact
